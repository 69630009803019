import React from 'react';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const lightboxOptions = {
  buttons: {
    showAutoplayButton: false,
    showCloseButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false
  }
};

const WorkGallery = () => {
  return (
    <SimpleReactLightbox>
      <SRLWrapper options={lightboxOptions}>
        <div className="galleryRow">
          <div className="galleryColumn">
            <img src="./images/work/work-example-5.jpeg" alt=""/>
            <img src="./images/work/work-example-6.jpeg" alt="TV studio and decking build" />
            <img src="./images/work/work-example-15.jpeg" alt="" />
            <img src="./images/work/work-example-16.jpeg" alt="" />
          </div>
          <div className="galleryColumn">
            <img src="./images/work/work-example-8.jpeg" alt="" />
            <img src="./images/work/work-example-10.jpeg" alt="" />
            <img src="./images/work/work-example-11.jpeg" alt="Rigging of directional signage at Murrayfield" />
            <img src="./images/work/work-example-12.jpeg" alt="" />
          </div>
          <div className="galleryColumn">
            <img src="./images/work/work-example-13.jpeg" alt="" />
            <img src="./images/work/work-example-14.jpeg" alt="" />
            <img src="./images/work/work-example-3.jpeg" alt="" />
            <img src="./images/work/work-example-4.jpeg" alt="Providing our media rigging, decking and staging services for BT Sport at the Etihad Stadium." />
          </div>
          <div className="galleryColumn">
            <img src="./images/work/work-example-1.jpeg" alt="Media rigging services provided to the BBC for the show 'Still Game'." />
            <img src="./images/work/work-example-2.jpeg" alt="Media rigging of a Spitfire suspended via chain-hoists for the BBC production 'Grandpas Great Espace'." />
            <img src="./images/work/work-example-7.jpeg" alt="Trusted rigging providers for Spidercam at Old Trafford" />
          </div>
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
}

export default WorkGallery;