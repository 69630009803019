import React from 'react';

const Card = ({ image, imgAlt, title, children }) => {
  return (
    <div class="max-w-sm rounded overflow-hidden shadow-lg jfr-service-card">
      <div class="px-4 py-4">
        <img src={image} alt={imgAlt} className="mb-2" />
        <div class="font-bold sm:text-xl text-lg mb-2">{title}</div>
        <>{children}</>
      </div>
    </div>
  );
}

export default Card;