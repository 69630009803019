import React from 'react';

// Components
import Card from './components/card';
import SectionTitle from './components/SectionTitle';
import WorkGallery from './components/workGallery';


function App() {

  return (
    <div className="w-screen">

      <header className="jfr-header w-screen absolute z-10">
        <div className="container content-center mx-auto py-2 px-4 grid grid-cols-1 sm:grid-cols-2">
          <div className="justify-self-center sm:justify-self-start"> 
            <a href="/" className="jfr-logo"><img src="./images/logo.png" alt="James Ferguson Rigging | Media Rigging Scotland" /></a>
          </div>

          <div className="justify-self-end text-white font-medium uppercase mt-10 text-white hidden sm:block">
            <div className="text-md">Media Rigging & Scaffolding</div>
            <div className="text-3xl text-right"><a href="tel:07446046500">07446 046 500</a></div>
          </div>

        </div>
      </header>

      <div className="w-screen bg-jfrGray banner-bg bg-hero bg-cover z-0">
        <div className="mx-auto my-auto absolute bottom-10">
          <a href="#work-section" className="jfr-btn font-bold uppercase text-white text-center px-4 py-4 shadow-lg w-auto mr-2 transition duration-500 ease-in-out">View some of our work</a>
        </div>
      </div>

      <div className="bg-white">
        <div className="container mx-auto py-12 px-4">
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
            <div className="content-center">
              <SectionTitle title="About Us" />
            </div>
            <div className="col-span-2">
              <p className="font-light leading-relaxed md:text-md">James Ferguson Rigging provides <strong className="font-bold">bespoke, professional media rigging services</strong> for the media, tv production and live events industry.</p>
              <p className="font-light leading-relaxed md:text-md">With over 35 years experience we specialise in delivering a full range of services to projects in the UK with clients ranging from live production, TV production and prestigious sporting events.</p>
              <p className="font-light leading-relaxed md:text-md">We are the preferred rigging company for a wide variety of clients having provided our media rigging services for clients including the <strong className="font-bold">BBC</strong>, <strong className="font-bold">SKY</strong> and <strong className="font-bold">BT Sport</strong> to name a few.</p>
              <p className="font-light leading-relaxed md:text-md">Known for our professionalism, safety and efficient work you can be assured that no job is too big or small for our professional team to handle; helping you with everything from planning to providing rigging equipment and the resources needed to ensure your rigging is safely and professionally installed.</p>
            </div>
          </div>
        </div>
      </div>


      <div className="w-screen bg-jfrGray md:py-12 sm:py-8 py-4 px-4">
        <div className="container mx-auto">

          <div className="w-full mx-auto grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 justify-items-center">
            <Card
              image="./images/work/work-example-15.jpeg"
              imgAlt="James Ferguson Rigging - Media Rigging Scotland"
              title="Media Rigging">
                <p className="text-gray-700 text-base">We provide a dedicated rigging service to the media industry from studio rigging to rigging for film and live events.</p>
            </Card>
            <Card
              image="./images/work/scaffolding-structures-scotland.jpeg"
              imgAlt="James Ferguson Rigging - Scaffolding Structures"
              title="Scaffolding Structures" >
                <p className="text-gray-700 text-base">Erecting scaffolding structures to suit your needs. We can build scaffolding structures in challenging locations of any scale for live events and much more.</p>
            </Card>
            <Card
              image="./images/work/stage-rigging-scotland.jpeg"
              imgAlt="James Ferguson Rigging - Stages and decking erection"
              title="Stages & Decking">
                <p className="text-gray-700 text-base">We are experts in building staging & temporary decking for your live events, tv production or festival needs working to meet your specific needs.</p>
            </Card>
          </div>

        </div>
      </div>

      <div className="w-screen md:py-6 py-4 px-4 bg-jfrRed">
        <div className="container mx-auto">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-2 text-white lg:text-2xl text-md break-all font-medium">
            <div>
              <p><strong className="block sm:inline lg:text-lg text-sm font-medium">Telephone:</strong> <a href="tel:07446046500">07446 046 500</a></p>
            </div>
            <div>
              <p><strong className="block sm:inline lg:text-lg text-sm font-medium">Email:</strong> <a href="mailto:jimcferguson@icloud.com">jimcferguson@icloud.com</a></p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-screen bg-white md:py-12 sm:py-8 py-4 px-4 jfr-red-border" id="work-section">
        <div className="container mx-auto">

        <div className="grid grid-cols-1 gap-4">
            <div className="content-center mb-4">
              <SectionTitle title="A selection of our work" />
            </div>
            <div>
              <WorkGallery />
            </div>
          </div>

        </div>
      </div>

    </div>
  );
}

export default App;